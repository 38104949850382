import React, { useState, useEffect } from 'react';
import styles from './AreaCliente.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomButton from '../../components/Button/button.tsx';
import ButtonDegrade from '../../components/ButtonDegrade/buttonDegrade.tsx';
import { useNavigate } from 'react-router-dom';
import { carinhoDeCompra } from '../../service/carinhoDeCompraService.js';
import { useCart } from '../../service/CartContext.js';

const CarinhoDeCompra: React.FC = () => {
    const [produtos, setProdutos] = useState<any[]>([]);
    const [totalItens, setTotalItens] = useState<number>(0);
    const navigate = useNavigate();
    const { removeFromCart } = useCart();

    return (
        <div className={styles.carinhoDeCompra_body}>
            <div className={styles.carinhoDeCompra_registerPage}>
            
            </div>
        </div >
    );
};

export default CarinhoDeCompra;