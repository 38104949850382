import React, { useState, useEffect } from 'react';
import styles from './CarinhoDeCompra.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomButton from '../../components/Button/button.tsx';
import ButtonDegrade from '../../components/ButtonDegrade/buttonDegrade.tsx';
import { useNavigate } from 'react-router-dom';
import { carinhoDeCompra } from '../../service/carinhoDeCompraService.js';
import { useCart } from '../../service/CartContext.js';

const CarinhoDeCompra: React.FC = () => {
    const [produtos, setProdutos] = useState<any[]>([]);
    const [totalItens, setTotalItens] = useState<number>(0);
    const navigate = useNavigate();
    const { removeFromCart } = useCart();

    useEffect(() => {
        const produtosSalvos = localStorage.getItem('carrinho');
        if (produtosSalvos) {
            const parsedProdutos = JSON.parse(produtosSalvos);
            setProdutos(parsedProdutos);
            setTotalItens(parsedProdutos.length);
        }
    }, []);

    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleItemClick = (index: number) => {
        setActiveIndex(index);
    };

    const handleFinalizarCompra = () => {
        const token = localStorage.getItem('access_token');

        if (!token) {
            alert('Você precisa estar logado para finalizar a compra.');
            navigate('/login'); // Redireciona para a página de login
            return;
        }
        else {
            console.log('Chegou no else');
            const idsProdutos = produtos.map(produto => produto.id);
            const storedUserId = localStorage.getItem('user_id');
            const result = carinhoDeCompra(idsProdutos, storedUserId);
        }

        // Aqui você pode adicionar a lógica para finalizar a compra
        console.log('Compra finalizada com sucesso!');
        alert('Compra finalizada com sucesso!');
    };

    const handleRemover = (id: string) => {
        console.log(`Tentando remover produto com ID: ${id}`);
        
        const produtosAtualizados = produtos.filter(produto => produto.id !== id);
        console.log("Produtos Atualizados:", produtosAtualizados);
    
        setProdutos(produtosAtualizados);
        localStorage.setItem('carrinho', JSON.stringify(produtosAtualizados));
    
        setTotalItens(produtosAtualizados.length);
    
        const produtoRemovido = produtos.find(produto => produto.id === id);
        if (produtoRemovido) {
            alert(`O produto ${produtoRemovido.nome} foi removido do carrinho.`);
        } else {
            alert("Produto não encontrado.");
        }
    
        console.log("Estado atual de produtos:", produtos);
    };
    

    return (
        <div className={styles.carinhoDeCompra_body}>
            <div className={styles.carinhoDeCompra_registerPage}>
                <div>
                    <div className={styles.carinhoDeCompra_Container} style={{ marginTop: '140px', backgroundColor: 'transparent' }}>
                        <div className={styles.carinhoDeCompraEsquerdo_Title}>
                            <h3>Produtos</h3>
                        </div>
                        <div className={styles.carinhoDeCompraDireito_Title}>
                            <h3>Valor</h3>
                        </div>
                    </div>


                    <div className={`${styles.containerCustom} mt-5`}>
                        <div className={styles.produtosContainer} style={{ width: '100%' }}>
                            {produtos.map((produto) => (
                                <div
                                    key={produto.id}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        backgroundColor: 'transparent',
                                        borderRadius: '12px',
                                        marginBottom: '20px',
                                        color: 'white',
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        position: 'relative',
                                    }}
                                >
                                    <div
                                        style={{
                                            left: '10px',
                                            cursor: 'pointer',
                                            color: 'red',
                                            fontSize: '2.4rem',
                                            marginRight: '5px'
                                        }}
                                        onClick={() => handleRemover(produto.id)}
                                    >
                                        <span>&times;</span>
                                    </div>

                                    <div className="containerProduto"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            backgroundColor: 'black',
                                            borderRadius: '12px',
                                            padding: '20px',
                                            color: 'white',
                                            width: '100%',
                                            position: 'relative',

                                        }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexGrow: 1,
                                                marginRight: '20px',
                                                width: 'auto',
                                                textAlign: 'start',
                                            }}
                                        >
                                            <img
                                                src={produto.imagemUrl}
                                                alt={produto.nome}
                                                style={{
                                                    width: '273px',
                                                    height: '172px',
                                                    objectFit: 'cover',
                                                    borderRadius: '8px',
                                                    marginRight: '20px',
                                                }}
                                            />

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'start',
                                                    flexGrow: 1,
                                                }}
                                            >
                                                <h4 style={{ margin: '0', fontSize: '1.8rem' }}>{produto.nome}</h4>
                                                <p
                                                    style={{
                                                        margin: '5px 0',
                                                        fontSize: '1rem',
                                                        color: '#aaaaaa',
                                                    }}
                                                >
                                                    {produto.descricao}
                                                </p>
                                                <span>PDUS: {produto.pdUs}</span>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                textAlign: 'right',
                                            }}
                                        >
                                            <p
                                                style={{
                                                    margin: '0',
                                                    fontSize: '1.8rem',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                <span style={{ fontSize: '1rem', marginRight: '5px' }}>R$</span>
                                                {produto.valor}
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                        <div className={styles.pagamento}>
                            <div className={styles.cupom}>
                                <h3>Código do cupom</h3>
                                <input
                                    type="text"
                                    className={styles.carinhoDeCompra_CampoPesquisa}
                                    placeholder="Aplicar cupom"
                                />
                                <ButtonDegrade
                                    className={`mt-3 ${styles.text_gradient}`}
                                    text="Aplicar cupom"
                                    onClick={() => { }}
                                    style={{
                                        color: 'white',
                                        padding: '5px 20px',
                                        height: '44px',
                                        width: '70%',
                                        display: 'flex',
                                        marginTop: '0px',
                                        alignItems: 'center',
                                        boxSizing: 'border-box',
                                    }}
                                />
                            </div>
                            <div className={styles.compra}>
                                <div className={styles.subtotais}>
                                    {/* Desconto Total */}
                                    <div className={styles.linha}>
                                        <div className={styles.descontoContainer}>
                                            <h3 className={`${styles.label} ${styles.text_gradient}`}>Desconto</h3>
                                            {/* Calculando o desconto total */}
                                            <p className={`${styles.valorDesconto} ${styles.text_gradient}`}>
                                                R$ {produtos.reduce((total, produto) => produto.valor - produto.desconto, 0).toFixed(2)}
                                            </p>
                                        </div>
                                        <div className={styles.subtotalContainer}>
                                            <span className={styles.texto}>Subtotal</span>
                                            {/* Calculando o subtotal (valor total sem o desconto) */}
                                            <p className={styles.valor}>
                                                R$ {produtos.reduce((total, produto) => total + produto.valor, 0).toFixed(2)}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Valor Total */}
                                    <div className={styles.linha}>
                                        <span className={styles.texto}>Valor total</span>
                                    </div>
                                    <div className={styles.linha}>
                                        <span className={styles.valorTotal}>
                                            {/* Calculando o valor total após o desconto */}
                                            R$ {produtos.reduce((total, produto) => total + (produto.desconto), 0).toFixed(2)}
                                        </span>
                                    </div>
                                </div>


                                <CustomButton
                                    className={"mt-3"}
                                    text="Finalizar compra"
                                    onClick={handleFinalizarCompra}
                                    style={{
                                        color: 'white',
                                        padding: '5px 20px',
                                        height: '44px',
                                        width: '75%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginTop: '0px',
                                        alignItems: 'center',
                                        boxSizing: 'border-box',
                                    }}
                                />
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
};

export default CarinhoDeCompra;