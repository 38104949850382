import React, { useState, useEffect } from 'react';
import styles from './createAccount.module.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { registerUser } from '../../service/createAccountService';
import { Pagination } from 'swiper/modules';
import Loading from '../../components/Loading/loading.tsx'; // Importação do componente de loading
import CustomButton from '../../components/Button/button.tsx'

const CreateAccount: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [cpf, setCPF] = useState<string>("");
  const [phone, SetPhone] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const images = ["carousel.jpg", "cadeado.png", "logo_pex.webp"];

  useEffect(() => {
    const fetchData = async () => {
      const token = "token quando tiver um";
      try {
        const data = await fetchIntroduction(token);
        if (data?.success) {
          setCurrentSlide(data.data.subTitulo);
        }
      } catch (error) {
        console.error("Erro ao buscar introdução:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const totalImages = images.length;
    let imagesLoaded = 0;

    const handleImageLoad = () => {
      imagesLoaded += 1;
      if (imagesLoaded === totalImages) {
        setLoading(false);
      }
    };

    images.forEach((image) => {
      const img = new Image();
      img.src = image;
      img.onload = handleImageLoad;
      img.onerror = handleImageLoad;
    });

    return () => {
      imagesLoaded = 0;
    };
  }, [images]);

  const validatePasswords = () => {
    if (password !== confirmPassword) {
      setError("As senhas não coincidem!");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!validatePasswords()) return;

    setLoading(true);
    try {
      console.log('Chegou até a aqui');
      await registerUser(name, email, password, cpf, phone);
      <a href="/login">Voltar</a>
    } catch (error) {
      console.error("Erro ao criar conta:", error);
      setError("Erro ao criar conta. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  // Usando o componente de loading aqui
  if (loading) {
    return (
      <div className="loading-screen">
        <Loading /> {/* Componente de loading */}
      </div>
    );
  }

  return (
    <div className={styles.register_page}>
      <div className={styles.form_section}>
        <div className={styles.cadastro}>
          <p className={styles.login_link} style={{ textAlign: 'left' }}>
          </p>
          <div className={styles.logo}>
            <img src="/Imagens/logo_pex.webp" alt="PortFolio Expert" />
          </div>

          <h1>Crie sua conta</h1>
          <p>Insira seus dados para criar sua conta</p>

          <div className={styles.containterCadastro}>
            <form onSubmit={handleSubmit}>
              {error && <p className={styles.error_message}>{error}</p>}
              <div className={styles.input_group}>
                <label>Seu nome</label>
                <div className={styles.input_container}>
                  <input type="text" placeholder="Digite aqui" value={name} onChange={(e) => setName(e.target.value)} />
                  <img src="/Imagens/mensagem.jpeg" alt="Ícone" className={styles.input_icon} />
                </div>
              </div>
              <div className={styles.input_group}>
                <label>E-mail</label>
                <div className={styles.input_container}>
                  <input type="email" placeholder="Digite aqui" value={email} onChange={(e) => setEmail(e.target.value)} />
                  <img src="/Imagens/mensagem.jpeg" alt="Ícone" className={styles.input_icon} />
                </div>
              </div>
              <div className={styles.input_group}>
                <label>Senha</label>
                <div className={styles.input_container}>
                  <input type="password" placeholder="Digite aqui" value={password} onChange={(e) => setPassword(e.target.value)} />
                  <img src="/Imagens/cadeado.jpeg" alt="Ícone" className={styles.input_icon} />
                </div>
              </div>
              <div className={styles.input_group}>
                <label>Confirmar senha</label>
                <div className={styles.input_container}>
                  <input type="password" placeholder="Digite aqui" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                  <img src="/Imagens/cadeado.jpeg" alt="Ícone" className={styles.input_icon} />
                </div>
              </div>
              <div className={styles.input_group}>
                <label>CPF</label>
                <div className={styles.input_container}>
                  <input type="text" placeholder="Digite aqui" value={cpf} onChange={(e) => setCPF(e.target.value)} />
                  <img src="/Imagens/cadeado.jpeg" alt="Ícone" className={styles.input_icon} />
                </div>
              </div>
              <div className={styles.input_group}>
                <label>Número de telefone</label>
                <div className={styles.input_container}>
                  <input type="text" placeholder="Digite aqui" value={phone} onChange={(e) => SetPhone(e.target.value)} />
                  <img src="/Imagens/cadeado.jpeg" alt="Ícone" className={styles.input_icon} />
                </div>
              </div>
              <CustomButton
                className={`mt-3 ${styles.loader}`}
                text="Criar conta"
                onClick={() => { }}
                style={{
                  color: 'white',
                  padding: '5px 20px',
                  height: '44px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxSizing: 'border-box',
                }}
              />
            </form>
          </div>

          <p className={styles.login_link}>
            Já tem uma conta? <a href="/login">Entre agora!</a>
          </p>
        </div>
      </div>

      <div className={styles.image_section}>
        <Swiper
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {images.map((item, index) => (
            <SwiperSlide key={index}>
              <img src={item} alt={`Slide ${index + 1}`} />
              <div className="gradient-overlay"></div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div >
  );
};

export default CreateAccount;
