import React, { createContext, useContext, useState, useEffect } from 'react';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        try {
            const storedCart = JSON.parse(localStorage.getItem('carrinho')) || [];
            setCartItems(storedCart);
        } catch (error) {
            console.error('Erro ao carregar o carrinho:', error);
            setCartItems([]);
        }
    }, []);

    const addToCart = (item) => {
        setCartItems((prev) => {
            const exists = prev.some(cartItem => cartItem.id === item.id);
            if (exists) {
                return prev;
            }
            const updatedCart = [...prev, item];
            localStorage.setItem('carrinho', JSON.stringify(updatedCart));
            return updatedCart;
        });
    };
    
    const removeFromCart = (id) => {
        setCartItems((prev) => {
            const updatedCart = prev.filter(item => item.id !== id);
            localStorage.setItem('carrinho', JSON.stringify(updatedCart));
            return updatedCart;
        });
    };

    return (
        <CartContext.Provider value={{ cartItems, addToCart, removeFromCart }}>
            {children}
        </CartContext.Provider>
    );
};
