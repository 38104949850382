import React, { useState } from 'react';
import style from './ListCourse.module.css'

export default function ListCourse({ title, description, value }) {
    return (
        <article>
            <form className={style.form}>
                <img className={style.produtoImg} src="/ImagemTeste.png" />

                <div className={style.descricao}>
                    <h1>{title}</h1>
                    <p>{description}</p>
                </div>

                <div className={style.valor}>
                    <h1>{value}</h1>
                </div>
            </form>
        </article>
    );
}