import React from "react";
import styles from "./modal.module.css";
import CustomButton from '../../components/Button/button.tsx';

interface IModal {
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
    title: string;
    description: string;
}

export function Modal({ isOpen, setOpen, title, description }: IModal) {
    if (isOpen) {
        return (
            <div className={styles.overlay}>
                <div className={styles.modal}>
                    <div className={styles.textos}>
                        <h2 className={styles.title}>{title}</h2>
                        <p className={styles.description}>{description}</p>
                    </div>
                    <div className={styles.buttonContainer}>
                        <CustomButton
                            text="Continuar"
                            onClick={() => setOpen(!isOpen)}
                            style={{
                                color: '#fff',
                                padding: '5px 20px',
                                height: '44px',
                                width: '90%',
                                display: 'flex',
                                marginTop: '0px',
                                alignItems: 'center',
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
}