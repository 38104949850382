import React from "react";
import { Sidebar } from "../../components/UserProfile/Sidebar/Sidebar.tsx";
import { Profile } from "../../components/UserProfile/Profile/Profile.tsx";

export default function UserProfile() {
    return (
        <div className='wrapper'>
            <Sidebar name="João Pedro"></Sidebar>
            <main>
                <Profile/>
            </main>
        </div>
    );
}