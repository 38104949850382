import React, { useState } from 'react';
import style from './Profile.module.css'
import InputMask from 'react-input-mask';
import CustomButton from '../../Button/button.tsx';

export function Profile() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState("");

    const handleNameChange = (e) => {
        const value = e.target.value;

        const regex = /^[A-Za-záàâãéèêíïóôõöúç]+$/;

        if (value === '' || regex.test(value)) {
            setFirstName(value);
        }
    };

    const handleNameChangeLast = (e) => {
        const value = e.target.value;

        const regex = /^[A-Za-záàâãéèêíïóôõöúç]+$/;

        if (value === '' || regex.test(value)) {
            setLastName(value);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value.replace(/\s+/g, ''));
    };

    return (
        <article className={style.profile}>
            <header className={style.header}>
                <h2>Dados pessoais</h2>
            </header>
            <main>
                <form className={style.form}>
                    <div className={`${style.formRow} ${style.row}`}>
                        <div className={style.formGroup}>
                            <label htmlFor="firstName">Nome</label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                placeholder="Digite seu nome"
                                value={firstName}
                                onChange={handleNameChange}
                            />
                        </div>
                        <div className={style.formGroup}>
                            <label htmlFor="firstName">Sobrenome</label>
                            <input
                                type="lastName"
                                id="lastName"
                                name="lastName"
                                placeholder="Digite seu sobrenome"
                                value={lastName}
                                onChange={handleNameChangeLast}
                                width={"4000px"}
                            />
                        </div>
                        <div className={style.formGroup}>
                            <div className={style.formGroup}>
                                <label htmlFor="email">E-mail</label>
                                <input type="email" id="email" name="email" placeholder="Digite seu e-mail" />
                            </div>
                        </div>
                    </div>
                    <div className={style.row}>
                        <div className={style.formGroup}>
                            <label htmlFor="phone">Telefone</label>
                            <InputMask
                                mask="(99) 99999-9999"  // Máscara para telefone
                                id="phone"
                                name="phone"
                                placeholder="Digite seu telefone"
                            />
                        </div>
                        <div className={style.formGroup}>
                            <label htmlFor="cep">CEP</label>
                            <InputMask
                                mask="99999-999"  // Máscara para CEP
                                id="cep"
                                name="cep"
                                placeholder="Digite seu CEP"
                            />
                        </div>
                    </div>
                </form>
            </main>
            <footer className={style.footer}>
                <CustomButton
                    className={`mt-3`}
                    text="Editar"
                    onClick={() => { }}
                    style={{
                        height: '60px',
                        width: '16rem',
                        fontSize: '1.5rem',
                        borderRadius: '8px'
                    }}
                />
            </footer>
        </article>
    );
}