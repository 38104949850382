// src/pages/CreateAccount.tsx
import React, { useState, useEffect } from 'react';
import styles from './Login.module.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import Loading from '../../components/Loading/loading.tsx';
import AuthService from '../../service/auth.service.js';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/Button/button.tsx'
import { jwtDecode } from "jwt-decode";


const CreateAccount: React.FC = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const [loadingData, setLoadingData] = useState<boolean>(true); // Loading para dados
    const [loadingPage, setLoadingPage] = useState<boolean>(true); // Loading para a página
    const images = ["carousel.jpg", "cadeado.png", "logo_pex.webp"];
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const token = "token quando tiver um";
            try {
                const data = await fetchIntroduction(token);
                if (data?.success) {
                    setCurrentSlide(data.data.subTitulo);
                }
            } catch (error) {
                console.error("Erro ao buscar introdução:", error);
            } finally {
                setLoadingData(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingPage(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        setLoadingData(true);

        try {
            // Realiza o login e obtém o token
            const response = await AuthService.login(email, password);

            // Supondo que o token JWT venha no campo `token` da resposta
            // const token = response.data.user.id;
            const token = response.data.token.accessToken;
            console.log("O Token: ", token);
            if (token) {
                // Decodifica o token JWT para acessar os claims
                const decodedToken: any = jwtDecode(token); // Use a biblioteca jwt-decode
                const userId = decodedToken.sub; // Pega o claim 'sub'

                // Salva o token e o 'sub' no localStorage
                localStorage.setItem("access_token", token);
                localStorage.setItem("user_id", userId);

                console.log("Usuário logado com ID:", userId);
            }

            // Navega para a página inicial
            navigate('/');
        } catch (error) {
            console.error("Erro ao fazer login:", error);
            setError("Erro ao fazer login. Tente novamente.");
        } finally {
            setLoadingData(false);
        }
    };


    const isLoading = loadingData || loadingPage;

    return (
        <div className={styles.register_page}>
            {isLoading && <Loading />}

            <div className={styles.form_section}>
                <div className={styles.cadastro}>
                    <div className={styles.logo}>
                        <img src="/Imagens/logo_pex.webp" alt="PortFolio Expert" />
                    </div>
                    <h1>Seja bem vindo!</h1>
                    <p>Insira seus dados para fazer o login</p>

                    <div className={styles.containterCadastro}>
                        <form onSubmit={handleSubmit}>
                            {error && <p className={styles.error_message}>{error}</p>}
                            <div className={styles.input_group}>
                                <label>E-mail</label>
                                <div className={styles.input_container}>
                                    <input type="email" placeholder="Digite aqui" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <img src="/Imagens/mensagem.jpeg" alt="Ícone" className={styles.input_icon} />
                                </div>
                            </div>
                            <div className={styles.input_group}>
                                <label>Senha</label>
                                <div className={styles.input_container}>
                                    <input type="password" placeholder="Digite aqui" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    <img src="/Imagens/cadeado.jpeg" alt="Ícone" className={styles.input_icon} />
                                </div>
                            </div>
                            <CustomButton
                                className={"mt-3"}
                                text="Entrar"
                                onClick={() => document.querySelector('form')?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))} // Dispara a submissão do formulário
                                style={{
                                    color: 'white',
                                    padding: '5px 20px',
                                    height: '44px',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '0px',
                                    alignItems: 'center',
                                    boxSizing: 'border-box',
                                }}
                            />

                        </form>
                    </div>

                    <p className={styles.login_link}>
                        Ainda não tem uma conta? <a href="/createAccount">Crie agora!</a>
                        <br />
                        Esqueceu a senha? <a href="/forgetPassword">Recuperar senha</a>
                    </p>
                </div>
            </div>

            <div className={styles.image_section}>
                <Swiper
                    pagination={{
                        dynamicBullets: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    {images.map((item, index) => (
                        <SwiperSlide key={index}>
                            <img src={item} alt={`Slide ${index + 1}`} />
                            <div className={styles.gradient_overlay}></div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default CreateAccount;