import React from "react";
import style from './UserProfile.module.css';
import { SidebarProfileOrders } from "../../components/UserProfile/SidebarProfileOrders/SidebarProfileOrders.tsx";
import { ProfileOrders } from "../../components/UserProfile/ProfileOrders/ProfileOrders.tsx";

export default function UserProfile() {
    return (
        <div className='wrapper'>
            <SidebarProfileOrders name="João Pedro"></SidebarProfileOrders>
            <main>
                <ProfileOrders></ProfileOrders>
            </main>
        </div>
    );
}