import React from "react";
import style from './SidebarProfileOrders.module.css';
import CustomButton from '../../Button/button.tsx';

export function SidebarProfileOrders({ name }) {
    return (
        <aside className={style.sidebar}>
            <div className={style.profile}>
                <div className={style.avatarProfile}>
                    <img className={style.avatar} src="/ProfileTeset.png" />
                </div>

                <div className={style.descriptionProfile}>
                    <span style={{ fontSize: '1.5rem' }}>Olá</span>
                    <strong>{name.length > 10 ? name.slice(0, 10) + "..." : name}</strong>
                </div>
            </div>

            <hr></hr>

            <footer className={style.footer}>
                <a href="/userprofile">
                    Meus dados
                </a>
                <CustomButton
                    className={`mt-3`}
                    text="Meus pedidos"
                    onClick={() => { window.location.href = "/userProfileOrder"; }}
                    style={{
                        height: '60px',
                        width: '100%',
                        fontSize: '1.5rem',
                        borderRadius: '8px'
                    }}
                />
            </footer>
        </aside>
    )
}