import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './navbar.module.css';
import CustomButton from '../Button/button.tsx';
import { Navbar, Nav, Container, Form } from 'react-bootstrap';
import { useCart } from '../../service/CartContext.js';

function NavScrollExample() { // Removido o prop `quantidadeCarrinho`, já que usamos o contexto
  const location = useLocation();
  const navigate = useNavigate();
  const { cartItems } = useCart(); // Pegando informações do carrinho diretamente do contexto

  const links = [
    { href: "/", label: "Home" },
    { href: "/pexAcademy", label: "Pex Academy" },
    { href: "/quemSomos", label: "Quem somos" },
    { href: "/pexConecta", label: "Pex Conecta" },
    { href: "/produtos", label: "Produtos" },
    { href: "/vip", label: "VIP" },
  ];

  return (
    <>
      <Navbar expand="lg" className={`${styles.navbar} ${styles.navbarBgBodyTertiary}`}>
        <Container fluid className={styles.containerMaxWidth}>
          <Navbar.Brand href="/" className={styles.navbarBrand}></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className={`${styles.navbarNav} mx-auto my-2 my-lg-0`}
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              {links.map(({ href, label }) => (
                <Nav.Link
                  key={href}
                  href={href}
                  className={`${styles.navLink} ${location.pathname === href ? styles.navLinkActive : ''}`}
                >
                  {label}
                </Nav.Link>
              ))}
            </Nav>
            <Form className="d-flex justify-content-center align-items-center">
              {/* Botão do Portal do Cliente */}
              <CustomButton
                text="Portal do cliente"
                onClick={() => { }}
                style={{
                  color: '#fff',
                  padding: '5px 20px',
                  height: '42px',
                  width: '218px',
                  display: 'flex',
                  margin: 'auto',
                  alignItems: 'center',
                  fontSize: '1.1rem'
                }}
                className="my-custom-class"
              />
              {/* Botão de Perfil */}
              <CustomButton
                text=""
                onClick={() => navigate('/userprofile')}
                style={{
                  color: '#fff',
                  padding: '5px 20px',
                  height: '36px',
                  width: '36px',
                  display: 'flex',
                  margin: 'auto',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'none',
                  backgroundImage: 'url(Imagens/perfil.webp)',
                  backgroundSize: 'cover',
                }}
                className={styles.my_custom_class}
              />
              {/* Botão de Compra */}
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <CustomButton
                  text=""
                  onClick={() => navigate('/CarinhoDeCompra')}
                  style={{
                    color: '#fff',
                    padding: '5px 20px',
                    height: '36px',
                    width: '36px',
                    display: 'flex',
                    margin: 'auto',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'none',
                    backgroundImage: 'url(Imagens/compra.webp)',
                    backgroundSize: 'cover',
                  }}
                  className={styles.my_custom_class}
                />
                {cartItems.length > 0 && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '-5px',
                      right: '-5px',
                      backgroundColor: 'red',
                      color: 'white',
                      borderRadius: '50%',
                      height: '20px',
                      width: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >
                    {cartItems.length}
                  </div>
                )}
              </div>

            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavScrollExample;
