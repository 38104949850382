import React, { useState, useEffect } from 'react';
import styles from './quemSomos.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomButton from '../../components/Button/button.tsx';
import { fetchProdutosPexAcademy } from '../../service/produtosPexAcademy.js';

const QuemSomos: React.FC = () => {
    return (
        <div className={styles.pexAcademy_body}>
            <div className={styles.pexAcademy_registerPage}>
                <div className={styles.pexAcademy_cabecalho}>
                    <h3 className={styles.pexAcademy_tituloCabecalho}>Quem somos</h3>
                    <p className={styles.pexAcademy_subTituloCabecalho}>
                        Somos uma empresa inovadora, focada no cliente e na solução de problemas reais das pessoas e organizações.
                        Buscamos trazer inspiração e inovação para as pessoas na geração de valor para os clientes e a sociedade. Juntos geramos conhecimento e um mundo de oportunidades.
                    </p>
                </div>
            </div>

            <div className="container py-4">
                <div className="container py-4">
                    <div className="row justify-content-center">
                        <div className="col-md-6 mb-4">
                            <div className="card p-4 text-start" style={{ height: "300px", backgroundColor: '#262626', color: 'white', borderRadius: '10px' }}>
                                <img src="Imagens/WhatsApp Image 2024-12-04 at 10.11.45.jpeg" alt="Ícone" className={styles.iconImage} />
                                <h2 className="card-title mb-3">Visão</h2>
                                <p className="card-text">
                                    Ser referência em soluções estratégicas de gestão, proporcionando resultados excepcionais e inovação contínua para nossos clientes.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-6 mb-4">
                            <div className="card p-4 text-start" style={{ height: "300px", backgroundColor: '#262626', color: 'white', borderRadius: '10px' }}>
                                <img src="Imagens/WhatsApp Image 2024-12-04 at 10.11.57.jpeg" alt="Ícone" className={styles.iconImage} />
                                <h2 className="card-title mb-3">Missão</h2>
                                <p className="card-text">
                                    Capacitar organizações e líderes a atingirem seu máximo potencial, oferecendo consultoria e treinamento de excelência em gerenciamento de projetos, programas e portfólios, utilizando estrategicamente a inteligência artificial com foco em entrega de valor e transformação organizacional.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="mb-4">
                            <div className="card p-4 text-start" style={{ height: "auto", backgroundColor: '#262626', color: 'white', borderRadius: '10px' }}>
                                <img src="Imagens/WhatsApp Image 2024-12-04 at 10.11.45.jpeg" alt="Ícone" className={styles.iconImage} />
                                <h2 className="card-title mb-3" style={{ fontSize: "2.4rem" }}>Valores</h2>
                                <hr />
                                <div style={{ display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "space-around", width: "100%" }}>

                                    <div style={{ fontSize: "1.0rem", paddingLeft: "20px", flex: 1 }}>
                                        <h2 className="card-subtitle mb-4" style={{ fontSize: "1.4rem" }}>Geração de valor</h2>
                                        <ul style={{ paddingLeft: "20px", maxWidth: "400px", wordWrap: "break-word", lineHeight: "1.5" }}>
                                            <li>Nosso foco é gerar valor, ao invés de apenas fazer entregas</li>
                                            <li>Focamos em entregar valor em todo relacionamento que temos</li>
                                            <li>Entendemos as dores e necessidades dos clientes do ponto de vista deles</li>
                                            <li>Buscamos entregar as soluções prioritárias da forma mais breve possível</li>
                                        </ul>
                                    </div>
                                    <div style={{ fontSize: "1.0rem", paddingLeft: "20px", flex: 1 }}>
                                        <h2 className="card-subtitle mb-4" style={{ fontSize: "1.4rem" }}>Audácia</h2>
                                        <ul style={{ paddingLeft: "20px", maxWidth: "400px", wordWrap: "break-word", lineHeight: "1.5" }}>
                                            <li>Buscamos inovação na criação de soluções</li>
                                            <li>Temos coragem de tomar decisões em ambientes complexos e ambíguos</li>
                                            <li>Somos resilientes e buscamos vantagem competitiva em situações adversas</li>
                                        </ul>
                                    </div>
                                    <div style={{ fontSize: "1.0rem", paddingLeft: "20px", flex: 1 }}>
                                        <h2 className="card-subtitle mb-4" style={{ fontSize: "1.4rem" }}>Visão Sistêmica</h2>
                                        <ul style={{ paddingLeft: "20px", maxWidth: "400px", wordWrap: "break-word", lineHeight: "1.5" }}>
                                            <li>Entendemos a realidade como complexa</li>
                                            <li>Recusamos o pensamento binário</li>
                                            <li>Consideramos o impacto das nossas ações no ambiente interno e externo</li>
                                            <li>Buscamos criar ecossistemas onde as partes sejam beneficiadas</li>
                                        </ul>
                                    </div>
                                    <div style={{ fontSize: "1.0rem", paddingLeft: "20px", flex: 1 }}>
                                        <h2 className="card-subtitle mb-4" style={{ fontSize: "1.4rem" }}>Aprimoramento Contínuo</h2>
                                        <ul style={{ paddingLeft: "20px", maxWidth: "400px", wordWrap: "break-word", lineHeight: "1.5" }}>
                                            <li>Somos sempre aprendizes</li>
                                            <li>Medimos o que fazemos</li>
                                            <li>Aprendemos com o que medimos</li>
                                            <li>Aplicamos o que aprendemos</li>
                                            <li>Perseguimos e eliminamos desperdícios</li>
                                        </ul>
                                    </div>
                                    <div style={{ fontSize: "1.0rem", paddingLeft: "20px", flex: 1 }}>
                                        <h2 className="card-subtitle mb-4" style={{ fontSize: "1.4rem" }}>Adaptação</h2>
                                        <ul style={{ paddingLeft: "20px", maxWidth: "400px", wordWrap: "break-word", lineHeight: "1.5" }}>
                                            <li>Não nos fechamos ao desconhecido</li>
                                            <li>Nos adaptamos à singularidade de cada relacionamento</li>
                                            <li>Improvisamos com responsabilidade quando o caminho planejado não é suficiente</li>
                                            <li>A experimentação faz parte do nosso processo de decisão</li>
                                            <li>Adaptamos nossos processos, produtos e serviços o mais rapidamente possível diante de mudanças internas e externas</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`${styles.historySection} container`}>
                        <h2 className={styles.title}>Conheça nossa história</h2>
                        <div className={styles.timeline}>
                            <div className={`${styles.timelineItem} row`}>
                                <div className="col-md-6 order-md-2">
                                    <p style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "10px" }}>
                                        Em 2011
                                    </p>

                                    <p className={styles.description} style={{ textAlign: "start" }}>
                                        Somos uma empresa focada no cliente e na solução de problemas reais das pessoas e organizações. Em 2011, vimos que faltava no mercado mundial e muito mais ainda no brasileiro, fontes relacionadas ao gerenciamento do portfólio e lançamos o primeiro livro do país exclusivo sobre o tema, intitulado Gerenciamento de Portfólio, do autor Norberto de Oliveira Almeida, da editora Brasport, sendo fruto da sua dissertação de mestrado.
                                    </p>
                                </div>
                                <div className="col-md-6 text-center">
                                    <img
                                        src="/Imagens/teste.webp"
                                        alt="Livro Gerenciamento de Portfólio"
                                        className={`${styles.image} `}
                                    />
                                </div>
                            </div>
                            <div className={`${styles.timelineItem} row`}>
                                <div className="col-md-6">
                                    <p style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "10px", textAlign: "end" }}>Em 2013</p>
                                    <p className={styles.description} style={{ textAlign: "end" }}>
                                        Logo em seguida, vimos que não existia uma metodologia de gerenciamento de portfólio no Brasil e lançamos em 2013 a primeira metodologia brasileira de gerenciamento de portfólio, usada como fonte primária por dezenas de organizações. O livro é intitulado Metodologia de Gerenciamento de Portfólio, dos autores Felipe Azevedo Almeida e Norberto de Oliveira Almeida, da editora Brasport.
                                    </p>
                                </div>
                                <div className="col-md-6 text-center order-md-1">
                                    <img
                                        src="Imagens/20e49a573859921615346a567975fee4.png"
                                        alt="Livro Metodologia de Gerenciamento de Portfólio"
                                        className={styles.image}
                                    />
                                </div>
                            </div>
                            <div className={`${styles.timelineItem} row`}>
                                <div className="col-md-6 order-md-2">
                                    <p style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "10px", textAlign: "start" }}>
                                        Em 2015
                                    </p>

                                    <p className={styles.description} style={{ textAlign: "start" }}>
                                        Vimos também que a maturidade média em gerenciamento de projetos das organizações, com base no modelo MMGP, tem evoluído muito pouco nos últimos 15 anos e começamos a analisar por que isto acontecia. Verificamos que as organizações focam nos processos, mas não investem adequadamente nos pilares do gerenciamento profissional do portfólio de projetos: pessoas capacitadas, metodologia, tecnologia e governança. Para apoiar as organizações neste sentido e acelerar a avanço da maturidade no país, criamos em 2015, uma ferramenta gratuita para que elas possam avaliar a sua prontidão nestes pilares, chamada PPM Ready (www.ppmready.portfolioexpert.com.br), que já foi usada por aproximadamente mil organizações nos últimos anos. Esta ferramenta foi criada como fruto da tese de doutorado nos Estados Unidos de nosso CEO, Norberto de Oliveira Almeida.
                                    </p>
                                </div>
                                <div className="col-md-6 text-center">
                                    <img
                                        src="Imagens/4cb5de732186e7fcddc8a6fea53b76df.png"
                                        alt="Livro Gerenciamento de Portfólio"
                                        className={styles.image} style={{ width: '280px', height: '280px' }}
                                    />
                                </div>
                            </div>
                            <div className={`${styles.timelineItem} row`}>
                                <div className="col-md-6">
                                    <p style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "10px", textAlign: "end" }}>Em 2019</p>
                                    <p className={styles.description} style={{ textAlign: "end" }}>
                                        Percebemos também que a criação de plataformas tem sido um dos fatores críticos de sucesso para organizações bem-sucedidas. Em 2019, criamos nossa plataforma de ensino à distância (EAD) e gamificação, chamada Game of Projects.
                                    </p>
                                </div>
                                <div className="col-md-6 text-center order-md-1">
                                    <img
                                        src="Imagens/15fbd032d5bbeafe1f7564c01cf751dd.png"
                                        alt="Livro Metodologia de Gerenciamento de Portfólio"
                                        className={styles.image} style={{ width: '280px', height: '180px' }}
                                    />
                                </div>
                            </div>
                            <div className={`${styles.timelineItem} row`}>
                                <div className="col-md-6 order-md-2">
                                    <p style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "10px", textAlign: "start" }}>
                                        Em 2020
                                    </p>

                                    <p className={styles.description} style={{ textAlign: "start" }}>
                                        Há alguns anos temos trabalhado na agilidade do negócio das empresas e produtos do nosso grupo, bem como de nossos queridos e valiosos clientes, mas notamos que as metodologias e frameworks existentes são muito complexas e difíceis de serem implementadas, principalmente em empresas de pequeno e médio porte. Nós tentamos implementar um destes modelos, mas acabamos desistindo. A partir daí, pensamos em criar o Business Agility Management, que é baseado em frameworks, metodologias e abordagens já consagradas no mercado e consegue fazer este trabalho de uma forma objetiva, simples e visual. Ao implantarmos na organização, trouxe uma agilidade incrível e retornos surpreendentes.Somos uma empresa ágil, flexível, inovadora e em constante mutação, só sabemos o que somos hoje e estamos sempre na busca do que seremos amanhã, mas com uma certeza: juntos, geramos conhecimento e um mundo de oportunidades!
                                    </p>
                                </div>
                                <div className="col-md-6 text-center">
                                    <img
                                        src="Imagens/1be84ccea8cdb4a431b5446d2ca6d320.png"
                                        alt="Livro Gerenciamento de Portfólio"
                                        className={styles.image} style={{ width: '280px', height: '180px' }}
                                    />
                                </div>
                            </div>
                            <div className={`${styles.timelineItem} row`}>
                                <div className="col-md-6">
                                    <p style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "10px", textAlign: "end" }}>Em 2021</p>
                                    <p className={styles.description} style={{ textAlign: "end" }}>
                                        Em 2021
                                        Em 2021 para ajudar as organizações na geração de valor, criamos o VM Ready®, que é um modelo de maturidade (ou prontidão) em gerenciamento do valor, para avaliar o estágio evolutivo de maturidade de uma organização ou área na implementação de uma gestão baseada na geração de valor ao cliente (customer-centric). É totalmente online e gratuito.
                                    </p>
                                </div>
                                <div className="col-md-6 text-center order-md-1">
                                    <img
                                        src="Imagens/294e00dc255d14503485ae5b997b276e.png"
                                        alt="Livro Metodologia de Gerenciamento de Portfólio"
                                        className={styles.image} style={{ width: '280px', height: '280px' }}
                                    />
                                </div>
                            </div>
                            <div className={`${styles.timelineItem} row`}>
                                <div className="col-md-6 order-md-2">
                                    <p style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "10px", textAlign: "start" }}>
                                        Em 2024
                                    </p>

                                    <p className={styles.description} style={{ textAlign: "start" }}>
                                        Desenvolvemos uma solução de avaliação de desempenho que capacita sua empresa a impulsionar o crescimento profissional de seus colaboradores, garantindo foco nos resultados que realmente importam. www.pexformance.com.br
                                    </p>
                                </div>
                                <div className="col-md-6 text-center">
                                    <img
                                        src="Imagens/b4d90327a58c66bb9123d01e518ec0ae.png"
                                        alt="Livro Gerenciamento de Portfólio"
                                        className={styles.image} style={{ width: '280px', height: '180px' }}
                                    />
                                </div>
                            </div>
                            <div className={`${styles.timelineItem} row`}>
                                <div className="col-md-6">
                                    <p style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "10px", textAlign: "end" }}>Em 2024</p>
                                    <p className={styles.description} style={{ textAlign: "end" }}>
                                        Lançamento exclusivo da primeira plataforma de IA para gerenciamento de projetos desenvolvida no Brasil. O PEXIA é uma solução inteligente que integra IA ao gerenciamento de projetos e portfólios, permitindo otimização de processos, automação de tarefas e análises preditivas com dados em tempo real.
                                    </p>
                                </div>
                                <div className="col-md-6 text-center order-md-1">
                                    <img
                                        src="Imagens/44b14bcf4059e3b5bd740139a72e69ba.png"
                                        alt="Livro Metodologia de Gerenciamento de Portfólio"
                                        className={styles.image} style={{ width: '280px', height: '280px' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div >
    );
};

export default QuemSomos;