import React, { useState } from 'react';
import style from './ProfileOrders.module.css'
import ListCourse from '../../Card/ListCourse/ListCourse.tsx'

export function ProfileOrders() {
    return (
        <article className={style.profile}>
            <header className={style.header}>
                <h2>Meus pedidos</h2>
            </header>
            <main>
                <ListCourse title="Afile + SCRUM - Mindset e fremework Ágil" description="PDUS: 11" value="RS 640.00" />
                <ListCourse title="Agile + SCRUM - Mindset e framework Ágil" description="PDUS: 11" value="RS 640.00" />
                <ListCourse title="Agile + SCRUM - Mindset e framework Ágil" description="PDUS: 11" value="RS 640.00" />
                <ListCourse title="Afile + SCRUM - Mindset e fremework Ágil" description="PDUS: 11" value="RS 640.00" />
            </main>
        </article>
    );
}