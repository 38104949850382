import React from 'react';
import './footer.css';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <img src="Imagens/logo_pex.webp" alt="Logo" className="footer-logo" />
                {/* Logo no topo com Institucional abaixo */}
                <div className="footer-section">
                    <h4>Institucional</h4>
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li><a href="#">Quem somos</a></li>
                        <li><a href="#">Pex Academy</a></li>
                        <li><a href="#">Pex Conecta</a></li>
                        <li><a href="#">Produtos</a></li>
                        <li><a href="#">VIP</a></li>
                    </ul>
                </div>
                {/* Seção Portfólio */}
                <div className="footer-section">
                    <h4>Portfólio</h4>
                    <ul>
                        <li><a href="#">Cursos</a></li>
                        <li><a href="#">Produtos</a></li>
                        <li><a href="#">Serviços</a></li>
                        <li><a href="#">Área VIP</a></li>
                    </ul>
                </div>
                {/* Seção Contato */}
                <div className="footer-section">
                    <h4>Contato</h4>
                    <ul>
                        <li><a href="#">contato@portfolioperfect.com.br</a></li>
                        <li><a href="#">(21) 98688-8504</a></li>
                    </ul>
                </div>
                {/* Seção Redes Sociais */}
                <div className="footer-section">
                    <h4>Redes sociais</h4>
                    <div className="social-icons">
                        <a href="#" className="social-icon"><img src="Imagens/youtube.webp" /></a>
                        <a href="#" className="social-icon"><img src="Imagens/facebook.webp" /></a>
                        <a href="#" className="social-icon"><img src="Imagens/logotipo-do-instagram.webp" /></a>
                        <a href="#" className="social-icon"><img src="Imagens/logotipo-do-linkedin.webp" /></a>
                    </div>
                </div>
            </div>
            <div className="footer-container-2">
                <div className="footer-bottom">
                    <hr />
                    <p>© 2024 Portfólio Expert. Todos os direitos reservados.
                        <a href="#">Privacidade</a>
                        <a href="#">Termos</a>
                        <a href="#">Cookies</a>
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;