import React, { useState, useEffect } from 'react';
import styles from './PexAcademy.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomButton from '../../components/Button/button.tsx';
import { fetchProdutosPexAcademy } from '../../service/produtosPexAcademy.js';
import { Modal } from '../../components/Modal/modal.tsx';
import { useCart } from '../../service/CartContext.js';

const PexAcademy: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [produtos, setProdutos] = useState<Array<{
        id: string;
        nome: string;
        imagemUrl: string;
        valor: number;
        desconto: number;
        pdUs: number;
    }>>([]);
    const { addToCart } = useCart();
    const [quantidadeCarrinho, setQuantidadeCarrinho] = useState<number>(0);
    const [open, setOpen] = useState<boolean>(false);
    const [titulo, setTitulo] = useState<string>("");
    const [mensagem, setMensagem] = useState<string>("");

    // Função para buscar os produtos
    const produtosPex = async () => {
        const token = "token quando tiver um";
        try {
            const data = await fetchProdutosPexAcademy(token);
            setProdutos(data.data);
        } catch (error) {
            console.error("Erro ao buscar os produtos:", error);
        }
    };

    // Atualiza o contador do carrinho
    const atualizarContadorCarrinho = () => {
        const carrinho = JSON.parse(localStorage.getItem('carrinho') || '[]');
        setQuantidadeCarrinho(carrinho.length);
    };

    // Adiciona produto ao carrinho e atualiza o contador
    const adicionarAoCarrinho = (item) => {
        const carrinho = JSON.parse(localStorage.getItem('carrinho') || '[]');
        const produtoExistente = carrinho.find(produto => produto.id === item.id);

        if (!produtoExistente) {
            carrinho.push(item);
            localStorage.setItem('carrinho', JSON.stringify(carrinho));
            atualizarContadorCarrinho();
        }
    };

    useEffect(() => {
        atualizarContadorCarrinho();
    }, []);

    const handleComprar = (id: string) => {
        const produtoSelecionado = produtos.find(produto => produto.id === id);
    
        if (produtoSelecionado) {
            // Verifica se o produto já está no carrinho
            const carrinho = JSON.parse(localStorage.getItem('carrinho') || '[]');
            const produtoNoCarrinho = carrinho.some(produto => produto.id === produtoSelecionado.id);
    
            if (!produtoNoCarrinho) {
                addToCart(produtoSelecionado);
                setTitulo(`O Produto: ${produtoSelecionado.nome} foi adicionado ao carrinho.`);
                setMensagem("Produto Adicionado ao Carrinho");
                carrinho.push(produtoSelecionado);
                localStorage.setItem('carrinho', JSON.stringify(carrinho));
            } else {
                setTitulo(`O produto: ${produtoSelecionado.nome} já está no carrinho.`);
                setMensagem("Aviso!");
            }
    
            setOpen(true);
            atualizarContadorCarrinho();
        } else {
            console.error("Produto não encontrado!");
        }
    };
    

    useEffect(() => {
        produtosPex();
        atualizarContadorCarrinho();
    }, []);

    const handleItemClick = (index: number) => {
        setActiveIndex(index);
    };

    return (
        <div className={styles.pexAcademy_body}>
            <div className={styles.pexAcademy_registerPage}>
                <div className={styles.pexAcademy_cabecalho}>
                    <h3 className={styles.pexAcademy_tituloCabecalho}>PEX Academy</h3>
                    <p className={styles.pexAcademy_subTituloCabecalho}>
                        Fermentum consectetur ipsum pellentesque mi orci ut viverra.
                    </p>
                </div>
            </div>

            <div className={styles.pexAcademy_ContainerPesquisa}>
                <div className={styles.pexAcademy_Title}>
                    <h3>Produtos</h3>
                </div>

                <div className={styles.pexAcademy_Container}>
                    <div className={styles.pexAcademy_Pesquisa}>
                        <div className={styles.inputWrapper}>
                            <span className={styles.icon}>
                                <img src="/Imagens/lupa.webp" alt="Lupa" />
                            </span>
                            <input
                                type="text"
                                className={styles.pexAcademy_CampoPesquisa}
                                placeholder="Pesquisar..."
                            />
                        </div>

                        <CustomButton
                            text="Buscar"
                            onClick={() => { }}
                            style={{
                                color: '#fff',
                                padding: '5px 20px',
                                height: '44px',
                                width: '140px',
                                display: 'flex',
                                marginTop: '0px',
                                alignItems: 'center',
                            }}
                        />
                    </div>

                    <ul className={styles.pexAcademy_Ul_Opcoes_Produtos}>
                        {['Com certificado', 'Curso Combo', 'Curso Individual', 'ScrumStudy'].map((item, index) => (
                            <li key={index}>
                                <a
                                    href="#"
                                    className={`${styles.pexAcademy_Opcoes_Produtos} ${activeIndex === index ? styles.active : ''}`}
                                    onClick={() => handleItemClick(index)}
                                >
                                    {item}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={`${styles.containerCustom} mt-5`}>
                    <div className="row">
                        {produtos.map(produtoMap => (
                            <div
                                key={produtoMap.id}
                                className="col-12 col-md-4 col-lg-3 mb-4"
                                style={{ border: 'none' }}
                            >
                                <div className="card h-100" style={{ border: 'none', display: 'flex', flexDirection: 'column', overflow: 'hidden', backgroundColor: 'black' }}>
                                    <img
                                        src={produtoMap.imagemUrl}
                                        alt={produtoMap.nome}
                                        className={styles.card_img_top}
                                        style={{
                                            width: '100%',
                                            height: '210px',
                                            borderRadius: '8px',
                                            objectFit: 'cover',
                                            display: 'block',
                                        }}
                                    />
                                    <div className="" style={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        flexGrow: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        textAlign: 'start'
                                    }}>
                                        <div>
                                            <h4 className="card-title" style={{ marginBottom: '24px', marginTop: '24px' }}>{produtoMap.nome}</h4>
                                            <p className="card-text mb-2" style={{ marginBottom: '20px' }}>PDUS: {produtoMap.pdUs}</p>
                                            <div className="d-flex justify-content-between align-items-center" style={{ marginBottom: '30px' }}>
                                                <p className="text-decoration-line-through mb-0" style={{ color: '#5F5F5F', fontSize: '1.4rem' }}>
                                                    <span style={{ fontSize: '1.0rem' }}>R$</span>
                                                    {produtoMap.valor}
                                                </p>
                                                <p className="fw-bold mb-0" style={{ color: 'white', fontSize: '1.4rem', marginLeft: '10px' }}>
                                                    <span style={{ color: 'white', fontSize: '1.0rem' }}>R$</span>
                                                    {produtoMap.desconto}
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <CustomButton
                                                className='mt-3'
                                                text="Comprar"
                                                onClick={() => handleComprar(produtoMap.id)}
                                                style={{
                                                    color: '#fff',
                                                    padding: '5px 20px',
                                                    height: '44px',
                                                    width: '100%',
                                                    display: 'flex',
                                                    marginTop: '0px',
                                                    alignItems: 'center',
                                                    boxSizing: 'border-box'
                                                }}
                                            />
                                            <Modal
                                                isOpen={open}
                                                title={mensagem}
                                                description={titulo}
                                                setOpen={setOpen}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PexAcademy;
