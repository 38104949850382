import React, { useState, useEffect } from 'react';
import { fetchIntroduction } from '../../service/homeService';
import { produtoHome } from '../../service/produtoHomeService';
import styles from './home.module.css';

const Home: React.FC = () => {
  const [subtext, setSubtext] = useState<string>("");
  const [titulo, setTitulo] = useState<string>("");
  const [epigrafe, setEpigrafe] = useState<string>("");
  const [descricaoBotao, setDescricaoBotao] = useState<string>("");
  const [tituloProduto, setTituloProduto] = useState<string>("");
  const [tituloImagemProduto, setTituloImagemProduto] = useState<string>("");
  const [textoProdutoEsquerdo, setTextoProdutoEsquerdo] = useState<string>("");
  const [tituloEsquerdoInternoProduto, setTituloEsquerdoInternoProduto] = useState<string>("");
  const [tituloDireitoInternoProduto, setTituloDireitoInternoProduto] = useState<string>("");
  const [textoInternoDireitoProtudo, setTextoInternoDireitoProtudo] = useState<string>("");
  const [textoInternoEsquerdoProtudo, setTextoInternoEsquerdoProtudo] = useState<string>("");

  const fetchSubtext = async () => {
    const token = "token quando tiver um";

    try {
      const data = await fetchIntroduction(token);
      if (data?.success) {
        setSubtext(data.data.subTitulo);
        setTitulo(data.data.titulo);
        setEpigrafe(data.data.epigrafe);
        setDescricaoBotao(data.data.descricaoBotao);
      } else {
        setSubtext("No data available");
      }
    } catch (error) {
      setSubtext("Failed to load subtext");
    }
  };

  const produtosDescricao = async () => {
    const token = "token quando tiver um";

    try {
      const data = await produtoHome(token);
      if (data?.success) {
        setTituloImagemProduto(data.data.titulo);
        setTituloProduto(data.data.tituloImagem);
        setTextoProdutoEsquerdo(data.data.ddescricaoImagem);
        setTituloEsquerdoInternoProduto(data.data.descricaoTituloImagemEsquerda);
        setTituloDireitoInternoProduto(data.data.descricaoSubImagemEsquerda);
        setTextoInternoDireitoProtudo(data.data.descricaoTituloImagemDireita);
        setTextoInternoEsquerdoProtudo(data.data.descricaoSubImagemDireita);
      } else {
        setSubtext("No data available");
      }
    } catch (error) {
      setSubtext("Failed to load subtext");
    }
  };

  useEffect(() => {
    fetchSubtext();
  }, []);
  useEffect(() => {
    produtosDescricao();
  }, []);
  return (
    <div className={styles.home}>
      <div className={styles.content_wrapper}>
        <div className={styles.home_header}>
          <p className={styles.ecosystem_text} dangerouslySetInnerHTML={{ __html: epigrafe }} ></p>
          <h1 dangerouslySetInnerHTML={{ __html: titulo }} />
          <p dangerouslySetInnerHTML={{ __html: subtext }}></p>
          <button className={styles.explore_button} dangerouslySetInnerHTML={{ __html: descricaoBotao }}></button>
        </div>
      </div>

      <div className={styles.content_wrapper_2}>
        <div className={styles.products_section}>
          <h2 dangerouslySetInnerHTML={{ __html: tituloProduto }}></h2>

          <div className={styles.header_container}>
            <img
              src="/home/Imagem.webp"
              alt="Imagem Superior"
              className={styles.header_image}
            />
            <div className={styles.container_header_text}>
              <div className={styles.header_text}>
                <h3 dangerouslySetInnerHTML={{ __html: tituloImagemProduto }}></h3>
                <p dangerouslySetInnerHTML={{ __html: textoProdutoEsquerdo }}></p>
              </div>
            </div>


            <div className={styles.header_text_hover}>
              <div className={styles.header_section}>
                <h3 dangerouslySetInnerHTML={{ __html: tituloEsquerdoInternoProduto }}></h3>
                <p dangerouslySetInnerHTML={{ __html: tituloDireitoInternoProduto }}></p>
                <button className={styles.explore_button}>Saiba mais</button>
              </div>

              <div className={styles.header_section}>
                <h3 dangerouslySetInnerHTML={{ __html: textoInternoDireitoProtudo }}></h3>
                <p dangerouslySetInnerHTML={{ __html: textoInternoEsquerdoProtudo }}></p>
                <button className={styles.explore_button_clean}>Solicitar demonstração</button>
              </div>
            </div>
          </div>

          <div className={styles.product_images}>
            <div className={styles.image_container}>
              <img src="/Home/ImagemEsquerda.webp" alt="Produto 1" className={styles.product_image} />
              <div className={styles.descricao_product}>
                <h3>Implemente a utilização de Inteligência Artificial na sua Organização</h3>
                <p>
                  Eleve sua produtividade a níveis inimagináveis e transforme a gestão de projetos e
                  portfólios com a PEIXA, nossa solução de IA personalizada para otimização de processos
                  e decisões estratégicas.
                </p>
              </div>
              <div className={styles.overlay}>
                <div className={styles.overlay_top}></div>
                <h3>O que é o PEXIA?</h3>
                <p>
                  O PEXIA é uma solução inteligente que integra IA ao gerenciamento de projetos e
                  portfólios, permitindo otimização de processos, automação de tarefas e análises
                  preditivas com dados em tempo real
                </p>
                <h3>Capacite sua Equipe em IA</h3>
                <p>
                  Além da implementação da IA, oferecemos treinamentos especializados para que seas
                  equipes dominem as ferramentas e processos automatizados, implusionando a
                  produtividade e eficiência.
                </p>
                <div className={styles.button_container}>
                  <button className={styles.explore_button}>Saiba mais sobre o PEXIA</button>
                  <button className={styles.explore_button_clean}>Solicitar treinamento de IA</button>
                </div>
              </div>
            </div>

            <div className={styles.image_container}>
              <img src="/Home/ImagemDireita.webp" alt="Produto 2" className={styles.product_image} />
              <div className={styles.descricao_product}>
                <h3>Dasboards Estratégicos e Sistemas de Gestão de Projetos</h3>
                <p>
                  Visualize e controle seus Negócios e Projetos com Dasboards Inteligentes
                </p>
              </div>
              <div className={styles.overlay}>
                <div className={styles.overlay_top}></div>
                <h3>O que é o PEXIA?</h3>
                <p>
                  O PEXIA é uma solução inteligente que integra IA ao gerenciamento de projetos e
                  portfólios, permitindo otimização de processos, automação de tarefas e análises
                  preditivas com dados em tempo real
                </p>
                <h3>Capacite sua Equipe em IA</h3>
                <p>
                  Além da implementação da IA, oferecemos treinamentos especializados para que seas
                  equipes dominem as ferramentas e processos automatizados, implusionando a
                  produtividade e eficiência.
                </p>
                <div className={styles.button_container}>
                  <button className={styles.explore_button}>Saiba mais sobre o PEXIA</button>
                  <button className={styles.explore_button_clean}>Solicitar treinamento de IA</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.announcement_section}>
          <div className={styles.left_column}>
            <h3>PPM READY & VM READY</h3>
            <h2>Avalie a maturidade da sua organização agora!</h2>
          </div>
          <div className={styles.right_column}>
            <p>Descubra o quão preparada está sua empresa para maximizar o desempenho em Gerenciamento de Projetos (PPM Ready) e Gerenciamento de Valor (VM Ready).</p>
            <p>O sucesso do seu portfólio de projetos e programas depende da maturidade com que você gerencia processos e otimiza o valor gerado. Com nossos modelos exclusivos de avaliação de maturidade, o PPM Ready e o VM Ready, você pode identificar o nível atual de sua organização e traçar um plano estratégico de melhoria.</p>
          </div>
        </div>

        <div className={styles.evaluation_section}>
          <div className={styles.evaluation_card}>
            <h3>PPM Ready: Gerenciamento de Projetos</h3>
            <p>Avalia o grau de maturidade no gerenciamento de portfólios, programas e projetos, considerando fatores como:</p>
            <ul>
              <li>
                <img src="/Imagens/check.png" alt="Check" className={styles.check_icon} />
                <div>
                  <strong>Governança</strong>
                  <p className={styles.description}>Breve descrição explicando a governança.</p>
                </div>
              </li>
              <li>
                <img src="/Imagens/check.png" alt="Check" className={styles.check_icon} />
                <div>
                  <strong>Metodologias</strong>
                  <p className={styles.description}>Breve descrição das metodologias.</p>
                </div>
              </li>
              <li>
                <img src="/Imagens/check.png" alt="Check" className={styles.check_icon} />
                <div>
                  <strong>Pessoas capacitadas</strong>
                  <p className={styles.description}>Descrição sobre capacitação das pessoas.</p>
                </div>
              </li>
              <li>
                <img src="/Imagens/check.png" alt="Check" className={styles.check_icon} />
                <div>
                  <strong>Ferramentas</strong>
                  <p className={styles.description}>Detalhes sobre o uso de ferramentas.</p>
                </div>
              </li>
            </ul>
            <button className={styles.explore_button}>Começar Avaliação PPM Ready</button>
          </div>
          <div className={styles.evaluation_card}>
            <h3>VM Ready: Gerenciamento de Valor</h3>
            <p>Focado em medir a capacidade de sua empresa em maximizar o valor através do gerenciamento eficaz de projetos e programas, avaliando fatores como:</p>
            <ul>
              <li>
                <img src="/Imagens/check.png" alt="Check" className={styles.check_icon} />
                <div>
                  <strong>Mensuração de Valor</strong>
                  <p className="description">Breve descrição sobre mensuração de valor.</p>
                </div>
              </li>
              <li>
                <img src="/Imagens/check.png" alt="Check" className={styles.check_icon} />
                <div>
                  <strong>Decisão Baseada em Dados</strong>
                  <p className={styles.description}>Explicação sobre decisões baseadas em dados.</p>
                </div>
              </li>
              <li>
                <img src="/Imagens/check.png" alt="Check" className={styles.check_icon} />
                <div>
                  <strong>Benefícios Tangíveis e Intangíveis</strong>
                  <p className={styles.description}>Descrição dos benefícios.</p>
                </div>
              </li>
            </ul>
            <button className={styles.explore_button}>Iniciar Avaliação VM Ready</button>
          </div>
        </div>

        <div className={styles.step_section}>
          <h3 className={styles.section_title}>PASSO A PASSO</h3>
          <h2 className={styles.how_it_works}>Como funciona?</h2>

          <div className={styles.steps_container}>
            <div className={styles.step_card}>
              <img src="/Imagens/check.png" alt="Passo 1 Icon" className={styles.step_icon} />
              <h4>Passo 1</h4>
              <p>Escolha entre PPM Ready ou VM Ready, dependendo do que você deseja avaliar.</p>
            </div>
            <div className={styles.step_card}>
              <img src="/Imagens/check.png" alt="Passo 2 Icon" className={styles.step_icon} />
              <h4>Passo 2</h4>
              <p>Responda ao nosso questionário detalhado, desenhado por especialistas para medir a maturidade de sua organização em diferentes áreas-chave.</p>
            </div>
            <div className={styles.step_card}>
              <img src="/Imagens/check.png" alt="Passo 3 Icon" className={styles.step_icon} />
              <h4>Passo 3</h4>
              <p>Receba um relatório personalizado com insights sobre seu nível atual de maturidade e recomendações práticas de melhoria.</p>
            </div>
          </div>
        </div>

        <div className={styles.training_section}>
          <div className={styles.training_section_content}>
            <div className={styles.training_header}>Treinamentos da Pex Academy</div>
            <h1 className={styles.training_title}>Desenvolva Habilidades em Gestão, Agilidade e IA</h1>
            <p className={styles.training_description}>
              Capacite sua equipe com nossos treinamentos práticos em Gerenciamento de Projetos, Portfólios, Agilidade e Inteligência Artificial. Inscreva-se hoje na Pex Academy.
            </p>
          </div>
          <div className={styles.overlay_training}>
            <div className={styles.overlay_training_content}>
              <div className={styles.overlay_item}>
                <h3>Gerenciamento de Portfólio e VMO</h3>
                <p>Domine a metodologia de gerenciamento de portfólio ou veja um curso para implementar um VMO.</p>
              </div>
              <div className={styles.overlay_item}>
                <h3>Inteligência Artificial</h3>
                <p>Domine a metodologia de gerenciamento de portfólio ou veja um curso para implementar um VMO.</p>
              </div>
              <div className={styles.overlay_item}>
                <h3>Metodologias Ágeis</h3>
                <p>Domine a metodologia de gerenciamento de portfólio ou veja um curso para implementar um VMO.</p>
              </div>
            </div>
            <button className={styles.explore_button}>Saiba mais sobre o PEXIA</button>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Home;